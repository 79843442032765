import { createReducer } from '../../utils/createReducer';
import { ClientRoom} from '../types';
import {addClientRoomInformation as addClientRoomInformationFUNCTION} from '../actions'
import {
  ADD_CLIENT_ROOM_INFORMATION, addClientRoomInformation
} from '../actions/types';
import { getClientId, getRoomId, setClientId, setRoomId } from '../../sessionStorage/room';

const client_id = getClientId()
const room_id = getRoomId()

const initialState: ClientRoom = {
    client_id: client_id || null,
    room_id: room_id || null

};

const roomReduser = {
  [ADD_CLIENT_ROOM_INFORMATION]: (state: ClientRoom, {payload}: ReturnType<typeof addClientRoomInformationFUNCTION>) => {
    const { client_id, room_id } = payload
    if(client_id) setClientId(client_id);
    if(room_id) setRoomId(room_id);
    return { ...state, client_id, room_id }
  },

};

export default (state: ClientRoom = initialState, action: addClientRoomInformation) => createReducer(roomReduser, state, action);
