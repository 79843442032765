import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';

import { GlobalState } from 'src/store/types';
import { AnyFunction } from 'src/utils/types';
import { openFullscreenPreview } from '../../store/actions';

import Conversation from './components/Conversation';
import Launcher from './components/Launcher';

import './style.scss';
import { Rnd } from 'react-rnd';

type Props = {
  title?: string;
  titleAvatar?: string;
  subtitle: string;
  onSendMessage: AnyFunction;
  sendAudioData: AnyFunction;
  onToggleConversation: AnyFunction;
  senderPlaceHolder: string;
  onQuickButtonClicked: AnyFunction;
  profileAvatar?: string;
  profileClientAvatar?: string;
  showCloseButton: boolean;
  fullScreenMode: boolean;
  autofocus: boolean;
  customLauncher?: AnyFunction;
  onTextInputChange?: (event: any) => void;
  chatId: string;
  launcherOpenLabel: string;
  launcherCloseLabel: string;
  launcherCloseImg: string;
  launcherOpenImg: string;
  sendButtonAlt: string;
  showTimeStamp: boolean;
  imagePreview?: boolean;
  zoomStep?: number;
  showBadge?: boolean;
  emojis?: boolean
}

let minSizeHeight: number = 60;
function WidgetLayout({
  title,
  titleAvatar,
  subtitle,
  onSendMessage,
  sendAudioData,
  onToggleConversation,
  senderPlaceHolder,
  onQuickButtonClicked,
  profileAvatar,
  profileClientAvatar,
  showCloseButton,
  fullScreenMode,
  autofocus,
  customLauncher,
  onTextInputChange,
  chatId,
  launcherOpenLabel,
  launcherCloseLabel,
  launcherCloseImg,
  launcherOpenImg,
  sendButtonAlt,
  showTimeStamp,
  imagePreview,
  zoomStep,
  showBadge,
  emojis
}: Props) {


  const dispatch = useDispatch();
  const { dissableInput, showChat, visible } = useSelector((state: GlobalState) => ({
    showChat: state.behavior.showChat,
    dissableInput: state.behavior.disabledInput,
    visible: state.preview.visible,
  }));
  const [size, setSize] = useState({ width: 450, height: 450 }); // Начальный размер
  const [position, setPosition] = useState({ x: window.innerWidth - size.width - 20, y: window.innerHeight - (showChat ? size.height : minSizeHeight) - 15 }); // Начальная позиция

  const messageRef = useRef<HTMLDivElement | null>(null);


  useEffect(() => {
    const handleResize = () => {
      setPosition({ x: window.innerWidth - size.width - 20, y: window.innerHeight - (showChat ? size.height : minSizeHeight) - 15 })
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [showChat, size]);

  useEffect(() => {
    if (showChat) {
      messageRef.current = document.getElementById('messages') as HTMLDivElement;
    }
    return () => {
      messageRef.current = null;
    }
  }, [showChat])

  const eventHandle = evt => {
    if (evt.target && evt.target.className === 'rcw-message-img') {
      const { src, alt, naturalWidth, naturalHeight } = (evt.target as HTMLImageElement);
      const obj = {
        src: src,
        alt: alt,
        width: naturalWidth,
        height: naturalHeight,
      };
      dispatch(openFullscreenPreview(obj))
    }
  }

  /**
   * Previewer needs to prevent body scroll behavior when fullScreenMode is True
   */
  useEffect(() => {
    const target = messageRef?.current;
    if (imagePreview && showChat) {
      target?.addEventListener('click', eventHandle, false);
    }

    return () => {
      target?.removeEventListener('click', eventHandle);
    }

  }, [imagePreview, showChat]);

  useEffect(() => {
    document.body.setAttribute('style', `overflow: ${visible || fullScreenMode ? 'hidden' : 'auto'}`)
  }, [fullScreenMode, visible])


  const handleToggleConversation = () => {
    if (position.y < size.height && !showChat) {
      setPosition(position => {
        const newY = position.y + size.height - 50

        return { x: position.x, y: newY }
      })
    }

    setPosition(position => {
      const newY = showChat
        ? position.y + size.height - minSizeHeight
        : position.y - size.height + minSizeHeight;

      return { x: position.x, y: newY }
    })

    onToggleConversation()
  }
  const isMobile = /Mobi|Android/i.test(navigator.userAgent);
  const TagName = isMobile ? 'div' : Rnd

  return (
    // @ts-ignore
    <TagName
      style={{
        zIndex: 1000,
        pointerEvents: 'auto'
      }}
      bounds="window"
      enableResizing={showChat && {
        top: true,
        right: true,
        bottom: true,
        left: true,
        topRight: true,
        bottomRight: true,
        bottomLeft: true,
        topLeft: true,
      }}
      minWidth={400}
      minHeight={showChat ? 250 : minSizeHeight}
      position={position}
      size={showChat ? size : { width: size.width, height: minSizeHeight }}
      onDragStop={(e, data) => {
        setPosition({ x: data.x, y: data.y })
      }}
      enableUserSelectHack={false}
      dragHandleClassName="draggable-area"
      onResizeStop={(_e, _direction, _ref, delta, position) => {

        setSize(size => {
          return {
            width: size.width + delta.width,
            height: showChat ? size.height + delta.height : size.height
          }
        })

        setPosition(position)
      }}

      className={cn('rcw-widget-container', {
        'rcw-full-screen': fullScreenMode,
        'rcw-previewer': imagePreview,
        'rcw-close-widget-container ': !showChat
      })
      }>

      {showChat &&
        <Conversation
          title={title}
          subtitle={subtitle}
          sendMessage={onSendMessage}
          sendAudioData={sendAudioData}
          senderPlaceHolder={senderPlaceHolder}
          profileAvatar={profileAvatar}
          profileClientAvatar={profileClientAvatar}
          toggleChat={handleToggleConversation}
          showCloseButton={showCloseButton}
          disabledInput={dissableInput}
          autofocus={autofocus}
          titleAvatar={titleAvatar}
          className={showChat ? 'active' : 'hidden'}
          onQuickButtonClicked={onQuickButtonClicked}
          onTextInputChange={onTextInputChange}
          sendButtonAlt={sendButtonAlt}
          showTimeStamp={showTimeStamp}
          emojis={emojis}
        />
      }
      {customLauncher ?
        customLauncher(onToggleConversation) :
        !fullScreenMode &&
        <Launcher
          toggle={handleToggleConversation}
          chatId={chatId}
          openLabel={launcherOpenLabel}
          closeLabel={launcherCloseLabel}
          closeImg={launcherCloseImg}
          openImg={launcherOpenImg}
          showBadge={showBadge}
          showChat={showChat}
          title={title}
        />
      }

    </TagName>
  );
}

export default WidgetLayout;
