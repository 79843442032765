import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { GlobalState } from '../../../../store/types';
import { setBadgeCount } from '../../../../store/actions';

import socket from '../../../../socket/socket';
import './style.scss';


const openLauncher = require('../../../../../assets/launcher_button.svg') as string;
const arrow = require('../../../../../assets/arrow-top.svg') as string;

type Props = {
  toggle: () => void;
  chatId: string;
  openLabel: string;
  closeLabel: string;
  closeImg: string;
  openImg: string;
  showBadge?: boolean;
  showChat: boolean;
  title?: string;
}

function Launcher({ toggle, openImg, closeLabel, title }: Props) {
  const dispatch = useDispatch();
  const { showChat, client_id, room_id } = useSelector((state: GlobalState) => ({
    showChat: state.behavior.showChat,
    badgeCount: state.messages.badgeCount,
    client_id: state.rooms.client_id,
    room_id: state.rooms.room_id,
  }));

  useEffect(() => {
    if (showChat && !socket.status && !socket.awaitConnection) {
      socket.connect('', '');
    }

    // window.addEventListener("unload", async function () {
    //   // @ts-ignore
    //   await socket.sendMessage(await new Blob(["\x04"]).arrayBuffer())
    // });

  }, [client_id, room_id, showChat])

  const toggleChat = () => {
    toggle();
    if (!showChat) dispatch(setBadgeCount(0));
  }

  if (showChat) {
    return null
  }

  return (
    <div className='rcw-launcher'>
      <div className='rcw-launcher-preview  draggable-area'>
        <img src={openImg || openLauncher} className="launcher-preview-icon" alt={closeLabel} />
        <h4 className='launcher-preview-title'>{title}</h4>
      </div>
      <img src={arrow} className='rcw-launcher-switcher' alt={closeLabel} onClick={toggleChat}/>
    </div>
  );
}

export default Launcher;
