import { useState } from "react";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'

import "./style.scss";
import { ConsentCheck } from "../ConsentCheck";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "@types";
import { updateUserDataStateAction } from "../../../../../../../../store/actions";

interface Props {
  children: React.ReactNode
}
export const UserData = ({children}: Props) => {
  const { userName, phone, isExistApproval } = useSelector((state: GlobalState) => (state.user.userData));
  const isUserAuthorized = useSelector((state: GlobalState) => (state.user.isUserAuthorized));

  if(isUserAuthorized) {
    return <div className="user-data">{children}</div>
  }
  
  const dispatch = useDispatch();
  
  const handelChangeUserData = (fiendName: string, value: unknown) => {
    dispatch(updateUserDataStateAction({[fiendName]: value}));
  }

  return (
    <div className="user-data">
      <div className="user-data__item">
      <span> Представьтесь пожалуйста <b>*</b></span>
        <input  value={userName} onChange={(event)=>handelChangeUserData('userName', event.target.value)} />
        </div>

      <div className="user-data__item">
      <label>Укажите Ваш номер <b>*</b></label>
      {/* @ts-ignore */}
      <PhoneInput
        addInternationalOption={false}
        defaultCountry="RU"
        countries={['RU', 'BY']}
        labels={{'RU': 'Россия', 'BY': 'Беларусь'}}
        value={phone}
        className={!phone || isValidPhoneNumber(phone) ? '': 'error'}
        onChange={(phone)=>handelChangeUserData('phone', phone || '')}/>
      </div>
      <ConsentCheck  checked={isExistApproval} onChange={(value)=>handelChangeUserData('isExistApproval', value)} /> 
      {children}
    </div>
  );
};
