import { getMappedRows } from "./helpers";
import "./style.scss";

import { Button } from "@types";

export const MessageButtons = ({ sendMessage, buttons }: { sendMessage: any, buttons: Button[] }) => {

  const handleClick = (text: string) => () => {
    sendMessage(text)
  }

  if (!buttons?.length) return null

  const mappedRows = getMappedRows(buttons);

  const renderButton = ({ id, tag, label, type, url, button_type, emoji }: Button) => {
    switch (button_type) {
      case "link":
        return (
          <a
            key={id}
            href={url}
            target='_blank'
            className="rcw-link-item"
          >
            <button
              className={`rcw-button-item rcw-button-item--${type || 'primary'}`}
              key={id}
            >
              {emoji} {label}
            </button>
          </a>
        );
      default:
        return (
          <button
            className={`rcw-button-item rcw-button-item--${type || 'primary'}`}
            key={id}
            onClick={handleClick(tag)}
          >
            {emoji} {label}
          </button>
        );
    }
  };

  return <div className="rcw-buttons">
    <div className="rcw-buttons-container">
      {mappedRows.map((row, index) => (
        <div key={`rcw-buttons-row-${index}`} className="rcw-buttons-row">
          {row.map((button) => (
            renderButton(button)
          ))}
        </div>
      ))}
    </div>
  </div>
};
