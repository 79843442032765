import { useState } from "react";
import "./style.scss";

interface Props {
  checked:boolean
  onChange: (isChecked: boolean) => void
}

export const ConsentCheck = ({ checked , onChange}: Props) => {
  return (
    <div className="consent-check">
      <label>
        <input
          type="checkbox"
          checked={checked}
          style={{ marginRight: "0.2rem"}}
          onChange={()=>onChange(!checked)}
        />
        <span>Я согласен(-на) на</span><a href={process.env.LINK_TO_CONSENT} target="_blank" onClick={(e) => e.stopPropagation()}> обработку персональных данных <b>*</b></a>
      </label>
    </div>
  );
};
