import { createReducer } from '../../utils/createReducer';
import { UserState } from '../types';

import {
  UserActions,
  UPDATE_USER_DATA,
  SET_USER_AUTHORIZED
} from '../actions/types';
import { getMessages } from '../../sessionStorage/room';

const initialState: UserState = {
  userData: {
    userName: '',
    phone: '',
    isExistApproval: false
  },
  isUserAuthorized: !!getMessages().length //нужно придумать что-то получше
};

const userReducer = {
  [UPDATE_USER_DATA]: (state: UserState, { userData }) => {
    return { ...state, userData: { ...state.userData, ...userData } }
  },

  [SET_USER_AUTHORIZED]: (state: UserState, { isUserAuthorized }) => {
    if (isUserAuthorized === state.isUserAuthorized) {
      return state
    }
    return { ...state, isUserAuthorized }
  }
};

export default (state: UserState = initialState, action: UserActions) => createReducer(userReducer, state, action);
