import { createReducer } from '../../utils/createReducer';
import { BehaviorState } from '../types';

import {
  BehaviorActions,
  CHANGE_BUTTON_START_STATE,
  TOGGLE_CHAT,
  TOGGLE_INPUT_DISABLED,
  TOGGLE_MESSAGE_LOADER,
} from '../actions/types';

import { getMessages } from '../../sessionStorage/room';

const getIsShowStartButton = () => {
  const messages = getMessages();

  if(!messages.length){
    return true
  }

  return messages.at(-1)?.text === '/end' 
} 

const initialState: BehaviorState = {
  showChat: false,
  disabledInput: false,
  messageLoader: false,
  isShowStartButton: getIsShowStartButton() //нужно придумать что-то получше
};

const behaviorReducer = {
  [TOGGLE_CHAT]: (state: BehaviorState) => ({ ...state, showChat: !state.showChat }),

  [TOGGLE_INPUT_DISABLED]: (state: BehaviorState) => ({ ...state, disabledInput: !state.disabledInput }),

  [TOGGLE_MESSAGE_LOADER]: (state: BehaviorState) => ({ ...state, messageLoader: !state.messageLoader }),

  [CHANGE_BUTTON_START_STATE]: (state: BehaviorState, { showStart }) => {
    return { ...state, isShowStartButton: showStart }
  },
};

export default (state: BehaviorState = initialState, action: BehaviorActions) => createReducer(behaviorReducer, state, action);
