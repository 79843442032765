import { MessagesState, MessageTypes } from "@types";

export const getClientId = () => sessionStorage.getItem("client_id");
export const getRoomId = () => sessionStorage.getItem("room_id");
export const setClientId = (clientId: string) => sessionStorage.setItem("client_id", clientId);
export const setRoomId = (roomId: string) => sessionStorage.setItem("room_id", roomId);

export const addMessage = (message: MessagesState['messages'][0]) => {

    let messages: MessagesState['messages'] = [];

    if (sessionStorage.getItem('messages')) {
        try {
            //@ts-ignore
            messages = JSON.parse(sessionStorage.getItem('messages'))
        } catch (e) {}
    }

    messages.push(message);

    if (messages.length > 100) {
        messages.shift();
    }

    sessionStorage.setItem('messages', JSON.stringify(messages));
}

export const getMessages = () => {
    //@ts-ignore
    return JSON.parse(sessionStorage.getItem('messages')) || [];
}
