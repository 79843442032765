const arrow = require('../../../../../../../assets/arrow-bottom.svg') as string;

import './style.scss';

type Props = {
  subtitle: string;
  toggleChat: () => void;
  showCloseButton: boolean;
  title?: string;
  titleAvatar?: string;
}

function Header({ title, subtitle, toggleChat, showCloseButton, titleAvatar }: Props) {
  return (
    <div className="rcw-header" >
      <div className='header-preview draggable-area'>
        {Boolean(titleAvatar) && <img className='header-preview-icon' src={titleAvatar} alt="profile" />}
        <div>
          {Boolean(title) && <h4 className='header-preview-title'>{title}</h4>}
          <span className='header-preview-subtitle'>{subtitle}</span>
        </div>
      </div>
      {showCloseButton && <button className="rcw-close-button" onClick={toggleChat}>
        <img src={arrow} className="rcw-close" alt="close" />
      </button>}
    </div>
  );
}

export default Header;
